<template>
    <header
        :class="`sticky backdrop-blur-md top-0 z-50 ${
            dark ? 'dark bg-gray-800/50' : 'bg-white/70'
        }`"
    >
        <div class="inset-x-0 top-0 max-w-7xl mx-auto z-50"></div>
        <nav
            class="z-50 flex items-center justify-between p-3 lg:px-8"
            aria-label="Global"
        >
            <div class="flex lg:flex-1">
                <Logo class="-m-1.5 p-1.5" :dark="dark" />
            </div>
            <div class="flex lg:hidden">
                <button
                    type="button"
                    class="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-gray-900 dark:text-gray-200"
                    @click="mobileMenuOpen = true"
                >
                    <span class="sr-only">Open main menu</span>
                    <Bars3Icon class="h-6 w-6" aria-hidden="true" />
                </button>
            </div>
            <div
                v-if="!page.props.user?.id"
                class="hidden lg:flex lg:gap-x-6 xl:gap-x-12"
            >
                <Link
                    v-for="item in navigation"
                    :key="item.name"
                    :href="item.href"
                    class="text-lg tracking-wider leading-6 text-bluewood-950 dark:text-white font-gothic uppercase"
                    >{{ item.name }}</Link
                >
            </div>
            <div
                class="hidden lg:flex lg:items-center lg:gap-x-6 lg:flex-1 lg:justify-end"
            >
                <a
                    v-if="!page.props.user?.id"
                    :href="
                        route('filament.user.auth.login', {
                            redirect: true,
                        })
                    "
                    class="text-lg font-gothic uppercase tracking-wider leading-6 text-bluewood-950 dark:text-white"
                    >Login <span aria-hidden="true">&rarr;</span>
                </a>
                <form
                    v-else
                    :action="
                        route('filament.user.auth.logout', {
                            redirect: true,
                        })
                    "
                    method="POST"
                >
                    <input
                        type="hidden"
                        name="_token"
                        :value="page.props.csrf_token"
                    />
                    <button
                        type="submit"
                        class="text-lg font-gothic uppercase tracking-wider leading-6 text-bluewood-950 dark:text-white"
                    >
                        Logout
                    </button>
                </form>
                <a
                    v-if="page.props.user?.id && page.props.user?.subscribed"
                    :href="route('billing')"
                    class="text-lg font-gothic uppercase tracking-wider leading-6 text-bluewood-950 dark:text-white"
                    >Manage Subscription</a
                >
                <a
                    :href="route('filament.user.auth.register')"
                    class="rounded-md bg-cyan-600 font-gothic uppercase px-3.5 py-2.5 text-lg font-semibold text-white shadow-sm hover:bg-cyan-700 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-cyan-600"
                    >Post a job</a
                >
            </div>
        </nav>
        <Dialog
            v-if="isClient"
            as="div"
            class="lg:hidden"
            @close="mobileMenuOpen = false"
            :open="mobileMenuOpen"
        >
            <div class="fixed inset-0 z-50" />
            <DialogPanel
                class="fixed inset-y-0 right-0 z-50 w-full overflow-y-auto bg-white px-6 py-6 sm:max-w-sm sm:ring-1 sm:ring-gray-900/10"
            >
                <div class="flex items-center justify-between">
                    <Link href="/" class="-m-1.5 p-1.5">
                        <span class="sr-only">{{ appName }}</span>
                        <img
                            class="h-8 w-auto"
                            :src="logoMarkUrl"
                            :alt="appName"
                        />
                    </Link>
                    <button
                        type="button"
                        class="-m-2.5 rounded-md p-2.5 text-gray-700"
                        @click="mobileMenuOpen = false"
                    >
                        <span class="sr-only">Close menu</span>
                        <XMarkIcon class="h-6 w-6" aria-hidden="true" />
                    </button>
                </div>
                <div class="mt-6 flow-root">
                    <div class="-my-6 divide-y divide-gray-500/10">
                        <div class="space-y-2 py-6">
                            <Link
                                v-for="item in navigation"
                                :key="item.name"
                                :href="item.href"
                                class="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                                >{{ item.name }}</Link
                            >
                        </div>
                        <div class="py-6">
                            <a
                                v-if="
                                    page.props.user?.id &&
                                    page.props.user?.subscribed
                                "
                                :href="route('billing')"
                                class="-mx-3 block rounded-lg px-3 py-2.5 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                                >Manage Subscription</a
                            >
                            <a
                                v-if="!page.props.user?.id"
                                :href="
                                    route('filament.user.auth.login', {
                                        redirect: true,
                                    })
                                "
                                class="-mx-3 block rounded-lg px-3 py-2.5 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                                >Login</a
                            >
                            <!-- logout -->
                            <form
                                v-else
                                :action="
                                    route('filament.user.auth.logout', {
                                        redirect: true,
                                    })
                                "
                                method="POST"
                            >
                                <input
                                    type="hidden"
                                    name="_token"
                                    :value="page.props.csrf_token"
                                />
                                <button
                                    type="submit"
                                    class="-mx-3 block rounded-lg px-3 py-2.5 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                                >
                                    Logout
                                </button>
                            </form>
                        </div>
                    </div>
                </div>
            </DialogPanel>
        </Dialog>
    </header>
</template>
<script setup>
import { Bars3Icon, XMarkIcon } from "@heroicons/vue/24/outline/index.js";
import { Dialog, DialogPanel } from "@headlessui/vue";
import { Link, usePage } from "@inertiajs/vue3";
import { computed, inject, onMounted, ref } from "vue";
import Logo from "@/Components/Guest/Logo.vue";

const route = inject("route");

const props = defineProps({
    dark: {
        type: Boolean,
        default: false,
    },
});

const page = usePage();
const mobileMenuOpen = ref(false);

const logoMarkUrl = computed(() => page.props.logoMarkUrl);

const navigation = [
    // { name: "Talent", href: "#" },
    { name: "Blog", href: route("blog.index") },
    { name: "Company", href: route("about") },
];

const isClient = ref(false);

onMounted(() => {
    isClient.value = true;
});
</script>
